import Axios from '@/services/Axios';
import { TokenUtils } from '@/utils/TokenUtils';
import { PurchaseOrderRequest } from '../models/PurchaseOrder/PurchaseOrderRequest';
import { CashCloseData } from '../models/PurchaseOrder/CashCloseData';
import { PurchaseOrderSingleItem } from '../models/PurchaseOrderSingleItem';
import { SavedQuotationData } from '../models/SavedQuotationData';

const resource = '/purchase-order';

export default {
  async createPurchaseOrder(employeeId, clientId, clientDiscount, total) {
    const payload = new PurchaseOrderRequest(employeeId, clientId,
      clientDiscount, total, 1);
    let response = null;
    try {
      response = await Axios.post(resource, payload);
    } catch (e) {
      console.error(e);
      response = e;
      return { error: 'asdasd' };
    }
    return response;
  },

  async createPurchaseOrderPayment(purchaseOrderId, amount, paymentMethod) {
    const payload = { purchaseOrderId, amount, paymentMethod };
    let response = null;
    try {
      response = await Axios.post(`${resource}/payment`, payload);
    } catch (e) {
      console.error(e);
      response = e;
      return { error: 'asdasd' };
    }
    return response;
  },

  async finishPurchaseOrder(employeeId, clientId, clientDiscount, total,
    payments, products, productsTotal, ignoreInventory) {
    const payload = {
      employeeId,
      clientId,
      clientDiscount,
      total,
      type: 1,
      payments,
      products,
      productsTotal,
      ignoreInventory,
    };
    let response = null;
    const headers = {
      Authorization: `Bearer ${TokenUtils.getToken()}`,
    };
    try {
      response = await Axios.post(`${resource}/finish-order`, payload, { headers });
    } catch (e) {
      console.error(e);
      response = e;
      return { error: 'asdasd' };
    }

    // eslint-disable-next-line
    return response;
  },

  async finishSavedPurchaseOrder(id) {
    const payload = {
      id,
    };
    let response = null;
    try {
      response = await Axios.put('quotation-order/finish-order', payload);
    } catch (e) {
      console.error(e);
      response = e;
      return { error: 'asdasd' };
    }
    return response;
  },

  async getCashCloseData(storeId) {
    const params = {
      storeId,
    };
    let response = null;
    let data = await Axios.get('cash-close/data', { params });
    data = data.data;
    response = new CashCloseData(data.ordersTotalAmount, data.cashAmount,
      data.debitCardAmount, data.creditCardAmount, data.checkAmount,
      data.transferAmount, data.orders, data.currentCashStart);
    return response;
  },

  async CashClose(amount, storeId) {
    let response = null;
    try {
      const payload = { amount, storeId };
      response = await Axios.put('cash-close', payload);
    } catch (e) {
      console.error(e);
      response = e;
      return { error: 'asdasd' };
    }
    return response;
  },

  async getPurchaseOrderData(id) {
    let response = null;
    let data = await Axios.get(`${resource}/${id}`);
    data = data.data;
    response = new PurchaseOrderSingleItem(data.id, data.employee, data.client, data.total,
      data.type, data.createdDt, data.isClosed, data.cashPayments, data.debitCardPayments,
      data.creditCardPayments, data.checkPayments,
      data.transferPayments, data.products);
    return response;
  },

  async saveQuotation(clientId, clientDiscount, total, products) {
    let response = null;
    const payload = {
      clientId,
      clientDiscount,
      total,
      products,
    };
    const headers = {
      Authorization: `Bearer ${TokenUtils.getToken()}`,
    };

    try {
      response = await Axios.post('quotation-order', payload, { headers });
    } catch (e) {
      console.error(e);
      response = e;
      return { error: 'asdasd' };
    }
    return response;
  },

  async getSavedQuotationsAutocompleteData(clientId = null, storeId = null) {
    let data = await Axios.get('quotation-order/autocomplete', {
      params: {
        clientId,
        storeId,
      },
    });
    data = data.data;
    return data;
  },

  async getSavedQuotationData(savedQuotationId, storeId) {
    let data = await Axios.get(`quotation-order/${savedQuotationId}`);
    data = data.data;
    const savedQuotationData = new SavedQuotationData(storeId, data.id, data.total,
      data.clientId, data.products);
    return savedQuotationData;
  },

  async deletePurchaseOrder(purchaseOrderId) {
    let response = null;
    try {
      response = await Axios.delete(`purchase-order/${purchaseOrderId}`);
    } catch (e) {
      console.error(e);
      response = e;
      return { error: 'No se pudo eliminar la venta, favor de contactar con un administrador' };
    }
    return response;
  },
};
